<template>
    <div>
        <b-tabs v-model="tabIndex" class="custom-tab" content-class="mt-3">
            <b-tab v-for="tab in tabs" :key="tab.route" @click="tabChange(tab.id)" :title="$t(`${tab.name}`)">
                <!--   PRICE CONTENT     -->
            </b-tab>
        </b-tabs>

        <router-view
            :success="successData"
            :exist="existData"
            :failed="failedData"
        />

        <div class="d-flex justify-content-end" style="gap:.5rem">
            <b-button variant="danger" @click="saveCheckCards(false)" class="mt-3  float-right">{{
                    $t('cancel')
                }}
            </b-button>
            <b-button variant="success" @click="saveCheckCards(true)" class="mt-3 float-right">{{
                    $t('save')
                }}
            </b-button>
        </div>

    </div>
</template>

<script>
import {
    BButton,
    BTab,
    BTabs
} from 'bootstrap-vue'
import {mapActions, mapState} from 'vuex'
import api from "@/services/api";

export default {
    name: "Index",
    components: {
        BButton,
        BTab,
        BTabs

    },
    data() {
        return {
            tabs: [
                {
                    id: 0,
                    name: 'success',
                    route: 'import-success'
                },
                {
                    id: 1,
                    name: 'failed',
                    route: 'import-failed'
                },
                {
                    id: 2,
                    name: 'exist',
                    route: 'import-exist'
                },
            ],
            activeTabIndex: 0,
        }
    },
    async created() {
        if (!(Object.keys(this.successData).length || Object.keys(this.failedData).length || Object.keys(this.existData).length)) {
            await this.fetchFileData()
        }
    },
    computed: {
        ...mapState('uploadFile', {
            successData: 'successData',
            failedData: 'failedData',
            existData: 'existData'
        }),
        tabIndex: {
            get() {
                const {name} = this.$route
                const index = this.tabs.filter(item => item.route === name)
                return index[0].id
            },
            set(value) {
                return value
            }
        },
    },
    methods: {
        ...mapActions('uploadFile', ['fetchFileData']),
        tabChange(currentTabs) {
            const index = this.tabs.filter(item => item.id === currentTabs)
            if (this.$route.name !== index[0].route) {
                this.$router.push({name: index[0].route})
            }
        },
        saveCheckCards(value) {
            const data = {
                save: value
            }
            api.cards.saveImport(data).then(() => {
                this.$router.push({name: 'cards'})
            })
        }
    }
}
</script>

<style scoped>

</style>